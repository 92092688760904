.Container-inscricao{
    width: 100vw;
}

.botoes_home_Evento{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.Inscricao-bt{
    width: 180px; /* Aumentado para melhorar a visibilidade */
    padding: 20px; /* Aumentado para melhorar a visibilidade */
    border: 3px solid #ffffff;
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.6s ease, background-color 0.6s ease, transform 0.6s ease;
    font-size: 1.2vw; /* Ajuste do tamanho da fonte */
}

.Inscricao-bt:hover {
 
    background-color: #009ced;
    border-color: #47acff;
    transform: scale(1.05);
}

.Inscricao-bt {
    background-color: #009ced;
    text-align: center;
    animation: slide-in-right 1s ease-out forwards;
    color: inherit;
    text-decoration: none;
}

.Inscricao-bt {
    color: white;
}


@media (max-width: 1200px) {
    .Inscricao-bt {
      width: 140px;
      padding: 20px;
      font-size: 2vw;
    }
  }
  
  @media (max-width: 999px) {
    .Inscricao-bt {
      font-size: 16px;
    }
  }
  
  @media (max-width: 768px) {
    .Inscricao-bt {
      width: 80%;
      padding: 15px;
      margin-bottom: 10px;
      font-size: 4vw;
    }
  }
  
  @media (max-width: 480px) {
    .Inscricao-bt {
      margin-top: 20px;
      width: 70%;
      padding: 10px;
      font-size: 24px;
    }
  }



  .tabela-inscricao table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.tabela-inscricao th, .tabela-inscricao td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.tabela-inscricao th {
  background-color: #f2f2f2;
  color: black;
}

.tabela-inscricao tr:nth-child(even) {
  background-color: #f9f9f9;
}

.tabela-inscricao tr:hover {
  background-color: #ddd;
}




.tabela-inscricao {
  display: flex;
  justify-content: center; /* Centraliza a tabela horizontalmente */
  margin: 20px 0;
}

.tabela-inscricao table {
  width: 80%; /* Define a largura da tabela como 80% */
  border-collapse: collapse;
}

.tabela-inscricao th, .tabela-inscricao td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.tabela-inscricao th {
  background-color: #f2f2f2;
  color: black;
}

.tabela-inscricao tr:nth-child(even) {
  background-color: #f9f9f9;
}

.tabela-inscricao tr:hover {
  background-color: #ddd;
}
