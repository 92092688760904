/* BlueBar.css */
.blue-bar {
    width: 100%;
    height: 10vh;
    background-color: rgb(20, 85, 178);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
  }
  
  .blue-bar h1 {
    color: white;
    margin: 0;
    font-size: 40px;
  }
  
@media (max-width: 999px){
  .blue-bar{
    height: 5vh;
  }
  .blue-bar h1 {
    font-size: 25px;
  }

}