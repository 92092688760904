/* FormattedText.css */
.TextoFormatado {
    max-width: 85vw;
    margin: auto;
  }
  
  .TextoFormatado h1 {
    font-size: 35px;
    margin-bottom: 0.5em;
  }
  
  .TextoFormatado p {
    font-size: 16px;
    line-height: 1.5;
    white-space: pre-line; /* Preserve os espaços e quebras de linha do texto */
    margin-bottom: 3%;
  }
  
  .TextoFormatado a {
    color: blue;
    text-decoration: underline;
    
  }

  @media (max-width: 999px) {
    .TextoFormatado h1 {
      font-size: 24px;
    }
    .TextoFormatado p {
      font-size: 14px;
    }
  }
  