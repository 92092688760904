.HeaderC {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: white;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    box-sizing: border-box;
}

.logo-image {
    height: 100px;
    width: 135px;
}

.HeaderC h1:hover {
    color: rgb(0, 156, 237);
    transform: scale(1.05);
}

.Nomes {
    display: flex;
    align-items: center;
    gap: 20px;
}

.Nomes h1 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    color: #000;
}

.Nomes a {
    text-decoration: none;
    color: inherit;
}

.dropdown {
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 99;
    cursor: pointer;
}

.dropdown-content a {
    color: white;
    padding: 12px 24px;
    text-decoration: none;
    display: block;
}

.dropdown:hover .dropdown-content {
    display: block;
    background-color: #009ced;
}

.dropdown-content a:hover {
    background-color: #0056b3;
}

.dropdown h1{
    cursor: pointer;
}

.linkedin {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 10px 15px;
}

.LinkedinText {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #009ced;
    border: 2px solid #009ced;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    transition: color 0.6s ease, background-color 0.6s ease, transform 0.6s ease;
}

.LinkedinText:hover {
    background-color: #009ced;
    color: white;
    transform: scale(1.05);
}


.hamburger{
    display: none;
}
/* Responsive Design */
@media (max-width: 999px){
    

    .logo-image {
        width: 68px;
        height: 50px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .Nomes {
        display: none;
    }

    .Nomes.show {
        z-index: 99;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50px;
        left: 0;
        background-color: white;
        border: 1px solid #e0e0e0;
        padding: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .hamburger {
        display: block;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
    }

    .linkedin {
        margin-top: 10px;
    }
}
